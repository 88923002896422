<template>
     <div>
    <div class="mb-2">
      <h4 class="font-size-16 text-gray-6">{{title}}</h4>
    </div>
    <div class="mb-4">
      <a-table
        :columns="columns"
        :dataSource="data"
        :pagination="pagination"
        :class="$style.table"
        :customRow="goToSingleUser"
      >
            <div
              slot="filterDropdown"
              slot-scope="{ setSelectedKeys, selectedKeys, confirm, clearFilters, column }"
              style="padding: 8px"
            >
              <a-input
                v-ant-ref="c => (searchInput = c)"
                :placeholder="`Search ${column.dataIndex}`"
                :value="selectedKeys[0]"
                style="width: 188px; margin-bottom: 8px; display: block;"
                @change="e => setSelectedKeys(e.target.value ? [e.target.value] : [])"
                @pressEnter="() => handleSearch(selectedKeys, confirm, column.dataIndex)"
              />
              <a-button
                type="primary"
                icon="search"
                size="small"
                style="width: 90px; margin-right: 8px"
                @click="() => handleSearch(selectedKeys, confirm, column.dataIndex)"
              >
                Search
              </a-button>
              <a-button size="small" style="width: 90px" @click="() => handleReset(clearFilters)">
                Reset
              </a-button>
            </div>
            <template slot="filterIcon" slot-scope="filtered">
              <a-icon
                type="search"
                :style="{ color: filtered ? '#108ee9' : undefined }"
              />
            </template>
        <template slot="userType" slot-scope="text">
          <span class="text-capitalize">{{removeUnderScore(text)}}</span>
        </template>
      </a-table>
    </div>
  </div>
</template>
<script>
export default {
  name: 'table',
  props: {
    title: {
      type: String,
      required: true,
    },
    columns: {
      type: Array,
      required: true,
      default: function() {
        return []
      },
    },
    data: {
      type: Array,
      required: true,
      default: function() {
        return []
      },
    },
  },
  data: function() {
    return {
      searchText: '',
      searchInput: null,
      searchedColumn: '',
      pagination: {
        pageSize: 20
      },
    }
  },
  methods: {
    goToSingleUser(record) {
      return {
        on: {
          click: event => {
            this.$router.push({ name: 'user', params: { user_id: record._id, user_type: record.user_type } })
          },
        },
      }
    },
    removeUnderScore(word) {
      const result = word.replace(/_/g," ");
      return result
    },
    handleSearch(selectedKeys, confirm, dataIndex) {
      confirm();
      this.searchText = selectedKeys[0];
      this.searchedColumn = dataIndex;
    },
    handleReset(clearFilters) {
      clearFilters();
      this.searchText = '';
    },
  },
}
</script>
<style lang="scss" module>
@import "./style.module.scss";
</style>
